import React from "react";
import Information from "./HiringInputs/Information";
import Inputs from "./HiringInputs/Inputs";
import HiringResponse from "./HiringResponse";

function HiringInputs() {
  return (
    <div className="flex mx-auto w-5/6 sm:w-4/6 border border-slate-400 rounded-lg sm:p-3">
      <div className="flex flex-col space-y-3 w-full h-full p-3 rounded-lg bg-rose-900">
        <Information />
        <Inputs />
        <HiringResponse />
      </div>
    </div>
  );
}

export default HiringInputs;
