import React, { useEffect, useMemo, useState } from "react";
import { BsPencil } from "react-icons/bs";
import { BiHomeSmile } from "react-icons/bi";
import { VscAccount } from "react-icons/vsc";
import { MdOutlineHelpOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { GiReceiveMoney } from "react-icons/gi";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setErrorsToNull } from "../redux/features/hiringSlice";
function UserInfo({ signOut }: any) {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const lng = navigator.language;
  const token = Cookies.get("access_token");
  const dispatch = useDispatch();
  return (
    <div className="bg-black/40 w-full h-full border-2 border-white ">
      <div className="border-b border-gray-300">
        <div className="flex p-2 space-x-3 cursor-pointer">
          <GiReceiveMoney size={24} color="#7A7479" />{" "}
          <span className="text-white">
            Token Left :<span className="font-semibold"> {}</span>
          </span>{" "}
        </div>

        <div className="flex p-2 space-x-3 cursor-pointer">
          <VscAccount size={24} color="#7A7479" />{" "}
          <span className="text-white"> Profile</span>{" "}
        </div>

        <div className="flex p-2 space-x-3 cursor-pointer">
          <MdOutlineHelpOutline size={24} color="#7A7479" />{" "}
          <span className="text-white"> Help Center</span>{" "}
        </div>
      </div>
      <div
        className="flex items-center justify-center mt-2 cursor-pointer p-2"
        onClick={() => {
          dispatch(setErrorsToNull());
          signOut();
        }}
      >
        <h1 className="text-white text-center text-lg">Log out</h1>
      </div>
    </div>
  );
}

export default UserInfo;
