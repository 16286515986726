import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Loading from "./Loading";
import Language from "./Language";
import { MdLanguage } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa";

function HiringResponse() {
  const [showLanguages, setShowLanguages] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const errors = useSelector((state: RootState) => state.hiring.errors);

  const [responseLanguage, setResponseLanguage] = useState<string>("EN");
  const responseEN = useSelector(
    (state: RootState) => state.hiring.hiringTextEN
  );
  const responseTR = useSelector(
    (state: RootState) => state.hiring.hiringTextTR
  );

  const loading = useSelector(
    (state: RootState) => state.hiring.hiringResponseLoading
  );

  const formattedTextEN = responseEN?.replace(/\n/g, "<br>") ?? "";
  const formattedTextTR = responseTR?.replace(/\n/g, "<br>") ?? "";

  const handleCopyToClipboard = () => {
    const textToCopy =
      responseLanguage === "EN" ? formattedTextEN : formattedTextTR;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <div>
      {loading && <Loading />}
      {errors && !loading && (
        <div className="flex flex-col ">
          {errors.map((err: any) => (
            <p className="text-white" key={err.message}>
              {err.message}
            </p>
          ))}
        </div>
      )}
      {!errors && !loading && responseEN && (
        <div className="flex flex-col space-y-2">
          <div className="flex justify-between">
            <div className="">
              <h1 className="text-white hidden lg:flex font-bold text-xl ">
                Generated Job Post by Cere
              </h1>
            </div>
            <div className="flex space-x-2">
              {isCopied && <p className="text-white font-semibold">Copied</p>}
              {!isCopied && (
                <FaRegCopy
                  onClick={handleCopyToClipboard}
                  size={28}
                  color="#9ca3af"
                  className="cursor-pointer"
                />
              )}
              <div className="flex">
                <div
                  className="border border-gray-400 cursor-pointer"
                  onClick={() => setResponseLanguage("EN")}
                >
                  <p className="text-white text-base font-medium px-2 py-1">
                    EN
                  </p>
                </div>
                <div
                  className="border border-gray-400 cursor-pointer"
                  onClick={() => setResponseLanguage("TR")}
                >
                  <p className="text-white text-base font-medium px-2 py-1">
                    TR
                  </p>
                </div>
              </div>
            </div>
          </div>
          {formattedTextEN &&
            (responseLanguage === "EN" ? (
              <div
                className="text-white"
                dangerouslySetInnerHTML={{ __html: formattedTextEN }}
              ></div>
            ) : (
              <div
                className="text-white"
                dangerouslySetInnerHTML={{ __html: formattedTextTR }}
              ></div>
            ))}
        </div>
      )}
    </div>
  );
}

export default HiringResponse;
