import { configureStore } from "@reduxjs/toolkit";
import persistStore from "redux-persist/es/persistStore";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import persistedReducer from "./features/userSlice";

import HiringReducer from "./features/hiringSlice";
export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),

  reducer: {
    hiring: HiringReducer,
    user: persistedReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
