export const languages = [
  "English",
  "Spanish",
  "French",
  "German",
  "Italian",
  "Portuguese",
  "Russian",
  "Chinese",
  "Japanese",
  "Korean",
  "Arabic",
  "Hindi",
  "Turkish",
  "Greek",
  "Ukrainian",
  "Polish",
  "Dutch",
  "Africans",
];

export const degrees = [
  "Primary School",
  "High School",
  "Associates",
  "Bachelors",
  "Masters",
  "PhD",
];

export const jobTypes = [
  "Full-time",
  "Part-time",
  "Contract",
  "Temporary",
  "Internship",
  "Volunteer",
];
