import logo from "../assets/beyazcerelogo.svg";

function Footer() {
  return (
    <div className="border-t border-slate-400 pb-2 mt-6 ">
      <div className="hidden sm:flex md:justify-between sm:justify-center sm:items-center w-full mx-auto flex-row flex-wrap md:w-4/6 border-b-2 border-slate-400 py-4">
        {/* left */}
        <div className="flex space-x-3 cursor-pointer">
          <img src={logo} alt="cere" className="w-10 h-10 md:w-14 md:h-14" />
          <div className="flex flex-col -space-y-1 md:-space-y-2">
            <h1 className="text-center text-white text-sm md:text-lg font-semibold">
              Cere Hiring
            </h1>
            <h1 className="text-center text-white text-sm md:text-lg font-semibold">
              Manager
            </h1>
          </div>
        </div>
        {/* center */}
      </div>
      <div className="flex items-center justify-center py-4  ">
        <p className="text-white text-sm">
          <span className="text-lg font-medium">Cere</span> | © 2023 Cerebrum
          Technologies | All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
