import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "user",
  storage,
  whitelist: ["user", "company", "token"],
};

interface userSliceState {
  user: null | string;
  company: null | string;
  token: null | string;
}

const initialState: userSliceState = {
  user: null,
  company: null,
  token: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserName: (state, { payload }) => {
      state.user = payload;
    },
    setCompanyName: (state, { payload }) => {
      state.company = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers: (builder) => {},
});

const persistedReducer = persistReducer(persistConfig, userSlice.reducer);

export const { setUserName, setCompanyName, setToken } = userSlice.actions;

export default persistedReducer;
