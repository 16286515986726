import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import HiringInputs from "../components/HiringInputs";
import Footer from "../components/Footer";

function HomePage() {
  return (
    <div className="bg-gradient-to-r from-red-500 to-red-800 min-h-screen flex flex-col">
      <Navbar />
      <HeroSection />
      <HiringInputs />
      <Footer />
    </div>
  );
}

export default HomePage;
