import React from "react";

function Information() {
  return (
    <div className="flex flex-col items-center mx-auto w-2/3 py-4 space-y-2">
      <h1 className="font-bold text-white text-lg sm:text-xl md:text-2xl lg:text-3xl text-center">
        Efficient and effective recruitment ads with Cere.
      </h1>
      <h3 className="font-medium text-white text-sm hidden sm:block  sm:text-base md:text-lg text-center">
        Achieve fast and successful recruitment results with Cere's assistance
        in crafting effective job ads.
      </h3>
    </div>
  );
}

export default Information;
