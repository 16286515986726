import React, { useState } from "react";
import { degrees, jobTypes, languages } from "../../data";
import HiringRequestButton from "./HiringRequestButton";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { createJobAd } from "../../redux/features/hiringSlice";
import Cookies from "js-cookie";
import axios from "axios";

function Inputs() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const token = Cookies.get("access_token");
  const [language, setLanguage] = useState("");
  const [degree, setDegree] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [militaryStatus, setMilitaryStatus] = useState("");
  const [industries, setIndustries] = useState("");
  const [skills, setSkills] = useState("");
  const [additionalRequirements, setAdditionalRequirements] = useState("");
  const [jobType, setJobType] = useState("");
  const [spokenLanguages, setSpokenLanguages] = useState("");
  const [experienceYear, setExperienceYear] = useState<null | number>(null);
  const [yearsCurrentRole, setYearsCurrentRole] = useState<null | number>(null);
  const [yearsCurrentCompany, setYearsCurrentCompany] = useState<null | number>(
    null
  );

  const dispatch = useAppDispatch();

  function submitHandler() {
    dispatch(
      createJobAd({
        BASE_URL,
        token,
        jobTitle,
        location,
        spokenLanguages,
        yearsOfExperiance: experienceYear,
        degree,
        yearsInCurrentRole: yearsCurrentRole,
        yearsInCurrentCompany: yearsCurrentCompany,
        skills,
        industries,
        profileLanguage: language,
        additionalRequirements: additionalRequirements,
        jobType,
        militaryServiceStatus: militaryStatus
      })
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <div className="flex space-x-3">
          <div className="flex flex-col space-y-1 flex-grow">
            <label className="text-white font-semibold">Job Title*</label>
            <input
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              type="text"
              placeholder="Senior Backend Developer"
              className=" bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-3 md:space-y-0">
          <div className="flex flex-col space-y-1 flex-grow">
            <label className="text-white font-semibold">Location*</label>
            <input
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              type="text"
              placeholder="Istanbul"
              className=" bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
          <div className="flex flex-col space-y-1 flex-grow">
            <label className="text-white font-semibold">
              Military Service Status
            </label>
            <input
              value={militaryStatus}
              onChange={(e) => setMilitaryStatus(e.target.value)}
              type="text"
              placeholder="Delayed"
              className=" bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        </div>

        <div className="flex flex-col space-y-2 md:flex-row md:space-x-3 md:space-y-0">
          <div className="flex flex-col space-y-1 flex-grow">
            <label className="text-white font-semibold">Industries</label>
            <input
              value={industries}
              onChange={(e) => setIndustries(e.target.value)}
              type="text"
              placeholder="Software Development"
              className=" bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>

          <div className="flex flex-col space-y-1 flex-grow">
            <label className="text-white font-semibold">
              Spoken Languages*
            </label>
            <input
              value={spokenLanguages}
              onChange={(e) => setSpokenLanguages(e.target.value)}
              type="text"
              placeholder="Turkish, English"
              className=" bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-3 md:space-y-0">
          <div className="flex flex-col space-y-1 flex-grow">
            <label className="text-white font-semibold">
              Years of Experience
            </label>
            <input
              value={experienceYear !== null ? experienceYear.toString() : ""}
              onChange={(e) => setExperienceYear(parseInt(e.target.value))}
              type="number"
              placeholder="2"
              className="bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
          <div className="flex flex-col space-y-1 flex-grow">
            <label className="text-white font-semibold">
              Years in Current Role
            </label>
            <input
              value={
                yearsCurrentRole !== null ? yearsCurrentRole.toString() : ""
              }
              onChange={(e) => setYearsCurrentRole(parseInt(e.target.value))}
              type="number"
              placeholder="2"
              className="bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
          <div className="flex flex-col space-y-1 flex-grow">
            <label className="text-white font-semibold">
              Years in Current Company
            </label>
            <input
              value={
                yearsCurrentCompany !== null
                  ? yearsCurrentCompany.toString()
                  : ""
              }
              onChange={(e) => setYearsCurrentCompany(parseInt(e.target.value))}
              type="number"
              placeholder="2"
              className="bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none w-full"
            />
          </div>
        </div>

        <div className="flex space-x-2 items-center justify-center">
          <div className="flex flex-col space-y-2 flex-grow">
            <div className="flex flex-col space-y-1">
              <label htmlFor="language" className="text-white font-semibold">
                Profile Language
              </label>
              <select
                onChange={(e: any) => setLanguage(e.target.value)}
                className="px-2 py-1 text-white bg-black/30 rounded-lg focus:outline-none w-full"
                name="language"
                id="language"
                value={language}
              >
                <option defaultValue="" value="">
                  Select Language
                </option>
                {languages.map((option) => (
                  <option
                    key={option}
                    className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col space-y-1">
              <label htmlFor="degree" className="text-white font-semibold">
                Degree
              </label>
              <select
                onChange={(e: any) => setDegree(e.target.value)}
                className="px-2 py-1 text-white bg-black/30 rounded-lg focus:outline-none w-full"
                name="degree"
                id="degree"
                value={degree}
              >
                <option defaultValue="" value="">
                  Select Degree
                </option>
                {degrees.map((option) => (
                  <option
                    key={option}
                    className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col space-y-1 flex-grow">
              <label htmlFor="degree" className="text-white font-semibold">
                Job Type*
              </label>
              <select
                onChange={(e: any) => setJobType(e.target.value)}
                className="px-2 py-1 text-white bg-black/30 rounded-lg focus:outline-none w-full"
                name="jobType"
                id="jobType"
                value={jobType}
              >
                <option defaultValue="" value="">
                  Select Job Type
                </option>
                {jobTypes.map((option) => (
                  <option
                    key={option}
                    className="hover:bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800"
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col space-y-1 justify-center w-1/2">
            <label className="text-white font-semibold">Skills</label>
            <textarea
              value={skills}
              onChange={(e) => setSkills(e.target.value)}
              rows={6}
              placeholder="Kotlin, PostgreSql"
              className="w-full resize-none bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-1 justify-center w-full">
          <label className="text-white font-semibold">
            Additional Requirements
          </label>
          <textarea
            value={additionalRequirements}
            onChange={(e) => setAdditionalRequirements(e.target.value)}
            rows={3}
            placeholder="friendly, team member , passionate..."
            className="w-full resize-none bg-black/30 rounded-lg text-white font-medium px-2 py-2 focus:outline-none"
          />
        </div>
      </div>
      <h1 className="text-white text-base font-medium">
        Fields marked with * are required!
      </h1>
      <div className="flex items-center justify-center" onClick={submitHandler}>
        <div
          className="cursor-pointer items-center border border-gradient-to-r from-pink-500 via-red-500 to-yellow-500  rounded-lg  hover:bg-gradient-to-r from-yellow-200 via-yellow-400 to-yellow-700"
          onClick={() => {}}
        >
          <p className="text-white text-xl font-semibold px-2 py-1">
            Generate Job Post
          </p>
        </div>
      </div>
    </div>
  );
}

export default Inputs;
