import React, { useState } from "react";
import { MdLanguage } from "react-icons/md";
function Language({ setResponseLanguage }: any) {
  return (
    <div className="bg-black/40 w-full h-full border-2 border-white  flex flex-col px-4 py-2 space-y-2">
      <h1
        className="text-base font-medium cursor-pointer text-white"
        onClick={() => setResponseLanguage("EN")}
      >
        EN
      </h1>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      <h1
        className="text-base font-medium cursor-pointer text-white"
        onClick={() => setResponseLanguage("TR")}
      >
        TR
      </h1>
    </div>
  );
}

export default Language;
